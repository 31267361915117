.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.grid-1fr {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.p-8 {
  padding: 32px;
}

.p-2 {
  padding: 12px;
}

.h-full {
  height: 100%;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.mt-32 {
  margin-top: 32px;
}
